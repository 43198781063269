@mixin detail-title-wrapper($max-width) {
  max-width: $max-width;
  max-height: 57px;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
}


@include media-breakpoint-down(md) {
  .detail-title-wrapper {
    @include detail-title-wrapper(200px);
  }
}

@include media-breakpoint-down(xs) {
  .detail-title-wrapper {
    @include detail-title-wrapper(100px);
  }
}
