// Personalizzazione Ovosodo - inizio
.select-form-control {

  height: 50px;

  .MuiInputLabel-filled {
    margin-top: 0 !important;
  }

  .MuiFilledInput-underline {
    &::before, &::after {
      display: none;
    }
  }

}

.MuiSelect-filled {
  background-color: transparent !important;
}
// Personalizzazione Ovosodo - fine


.exp-img-selection-menu ul {
  display: flex;
  max-width: 405px;
  flex-wrap: wrap;
}

.select-form-control .MuiInputLabel-filled.exp-img-selection-label {
  margin-top: 14px !important;
}
