// Personalizzazione Ovosodo - inizio
.MuiTextField-root {
  .MuiFilledInput-underline {
    &::before, &::after {
      display: none;
    }
  }

  .MuiInputAdornment-filled.MuiInputAdornment-positionEnd {
    //margin-top: 15px;
  }

  .MuiFilledInput-underline.Mui-focused {
    .MuiInputAdornment-filled.MuiInputAdornment-positionEnd {
      margin-right: -1px;
    }
  }
}

// Personalizzazione Ovosodo - fine
