body {
  font-family: $font-family-base;
  background-color: #F2F7FF !important; // overrides Mui default
}

input,
button,
select,
optgroup,
textarea {
  margin: 0; // Remove the margin in Firefox and Safari
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
