// Personalizzazione Ovosodo - inizio
.MuiAutocomplete-root .MuiFilledInput-underline {
  &::before, &::after {
    display: none;
  }

  &.MuiInputBase-hiddenLabel {
    padding-top: 0;
  }

  & .MuiInputAdornment-root.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
    margin-top: 0;
  }
}
// Personalizzazione Ovosodo - fine
