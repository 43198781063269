.selected-items.MuiSnackbar-root {
  background-color: #fff;
  border: 3px solid #05203B1C;
  border-radius: 18px;
  box-shadow: 0 3px 15px #7991AA33;
  justify-content: flex-start;
  position: absolute;
  width: calc(100% - 48px);

  .selected-counter {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: #3315D6;
    color: $white;
  }

  .unselect-button {
    cursor: pointer;

    & > .MuiTypography-root {
      text-decoration: underline;
      opacity: 0.6;
    }
  }
}
