.MuiToolbar-root {
  min-height: 40px !important;
  padding-top: 10px;
}

.menu-header {
  padding-left: 24px;
  display: flex;
  align-items: center;
  background-color: $secondary;
  color: $inheritDefault1;
  width: 100%;
  height: 64px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.logo-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  width: 100%;

  & > a {
    flex-grow: 1;
    margin-left: 4px;
  }

  & .MuiButtonBase-root {
    color: #7991aa;
    margin-top: 0 !important;
  }
}
