@import "../../zucchetti";

$card-width-xs: 300px;
$card-width-sm: 340px;
$card-width-md: 380px;

.MuiCard-root {
  box-shadow: none !important;

  &:hover {
    box-shadow: 0 6px 10px rgb(0 0 0 / 10%) !important;
  }

  &.selected-card {
    border: 2px solid $green-primary;

    &:hover {
      box-shadow: none;
    }
  }

  &.disabled-card {
    opacity: .75;
  }
}

.user-card-wrapper {
  overflow: hidden;
  flex-basis: $card-width-xs;
  & .MuiCardContent-root:last-child {
    padding-bottom: 16px;
  }
}

.user-card {
  max-width: $card-width-xs;
  //height: 205px;
  margin: 0 auto;
  border: 2px solid transparent;

  .MuiCardContent-root {
    height: 100%;

    .MuiCardContent-root:last-child {
      padding-bottom: 16px;
    }
  }

  .MuiCardActionArea-focusHighlight {
    background-color: transparent;
  }

  .travel-policy {
    @include ellipsis-multiline(calc(100% - 50px), 44px);
  }
}


.tp-card-wrapper {
  flex-basis: $card-width-xs;
}

.tp-card {
  max-width: $card-width-xs;
  height: 165px;
  margin: 0 auto;
  border: 2px solid transparent;

  .MuiCardContent-root {
    height: 100%;

    .MuiCardContent-root:last-child {
      padding-bottom: 16px;
    }
  }

  .MuiCardActionArea-focusHighlight {
    background-color: transparent;
  }

}

.tp-exp-card {
  width: 275px;
  height: 115px;
  margin: 0 auto;
  border: 2px solid transparent;
  background-color: #E7FCFE !important;
  border-radius: 16px !important;

  .MuiCardContent-root {
    height: 100%;
  }

  .MuiCardContent-root:last-child {
    padding-bottom: 16px;
  }

  .MuiCardActionArea-focusHighlight {
    background-color: transparent;
  }

}

.tp-exp-card.tp-exp-card-new {
  background-color: #DDF7F9 !important;
  border: 2px dashed #14C6D54D;
}


.exp-card-new,
.exp-card {
  background-color: #DDF7F9 !important;
  max-width: $card-width-xs;
  height: 50px;
  margin: 0 auto;
  border: 2px solid transparent;

  border-radius: 12px !important;

  .MuiCardContent-root {
    height: 100%;
    padding: 12px 16px;
  }
  .MuiCardContent-root:last-child {
    padding-bottom: 12px;
  }

  .MuiCardActionArea-focusHighlight {
    background-color: transparent;
  }

}

.exp-card-new {
  background-color: #ffffff !important;
  border: 2px dashed #14C6D54D;
}

.tp-exp-title {
  color: #0E053B;
  margin-top: 6px;
  line-height: 20px !important;
}

@include media-breakpoint-up(sm) {
  .tp-card-wrapper,
  .user-card-wrapper {
    flex-basis: $card-width-sm;
  }

  .tp-card,
  .user-card {
    max-width: $card-width-sm;
  }

  .tp-card,
  .user-card {
    margin: 0;
  }
}

@include media-breakpoint-up(md) {
  .tp-card-wrapper,
  .user-card-wrapper {
    flex-basis: $card-width-md;
  }

  .tp-card,
  .user-card {
    max-width: $card-width-md;
  }
}


