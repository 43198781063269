.recharts-wrapper {
  font-family: $font-family-base;
}

$tooltip-triangle-size: 13;

@mixin custom-tooltip($max-height: 90px, $triangle-offset: 50%) {
  width: 220px;
  height: 90px;
  max-width: 220px;
  max-height: $max-height;
  overflow: hidden;
  background: $white;
  border: none;
  border-radius: 5px;
  box-shadow: 0 5px ($tooltip-triangle-size * 1px) #44444F4E;
  padding: 10px;
  display: flex;
  flex-direction: column;
  position: relative;

  &::after {
    content: '';
    position: fixed;
    left: calc($triangle-offset - ($tooltip-triangle-size / 2) * 1px);
    bottom: -($tooltip-triangle-size / 2) * 1px;
    width: $tooltip-triangle-size * 1px;
    height: $tooltip-triangle-size * 1px;
    transform: rotate(45deg);
    background-color: $white;
    //box-shadow: 0 5px 11px #44444F4E;
  }
}

.custom-tooltip-total {
  @include custom-tooltip(70px);
}

.custom-tooltip-data {
  @include custom-tooltip;
}

.custom-tooltip-dashboard-total {
  @include custom-tooltip(70px);
}

.custom-tooltip-dashboard-category {
  width: 150px;
  height: 70px;
  background: $white;
  border: none;
  border-radius: 5px;
  box-shadow: 0 5px ($tooltip-triangle-size * 1px) #44444F4E;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
