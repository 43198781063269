//  Core

$text-field-warning: #e0a905;

// Personalizzazione Ovosodo - inizio
.MuiInputLabel-filled {
  margin-top: 2px;

  &.Mui-disabled {
    color: rgba(0, 0, 0, 0.6) !important;
  }
}

.MuiFilledInput-root {
  border-radius: 8px;
  background-color: transparent !important;
  border: 1px solid #05203B27;
  height: 50px;

  .MuiFilledInput-input {
    margin-left: -1px;
    margin-right: -1px;

    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.87);
      -webkit-text-fill-color: rgba(0, 0, 0, 0.87);
    }
  }

  &:hover {
    border-color: rgba(3, 18, 33);
  }

  &.Mui-focused {
    border-color: $primary;
    border-width: 2px;

    .MuiFilledInput-input {
      margin-left: -2px;
      margin-right: -2px;
    }
  }

  &.MuiInputBase-colorWarning {
    border-color: $text-field-warning;

    & + .MuiFormHelperText-contained {
      color: $text-field-warning;
    }
  }

  &.Mui-error {
    border-color: $red;
  }

  &.Mui-disabled {
    background-color: #05203B0B !important;
    border: none;
  }
}

.pwdRecoveryField > .MuiFormHelperText-root {
  text-align: right;
  margin: 4px;
}

.pwdRecoveryField.MuiFormControl-root {
  margin: 0 0 10px;
}

.MuiInputBase-multiline {
  height: auto;
}
// Personalizzazione Ovosodo - fine

.text-field-bg-white {
  background-color: $inheritDefault1;
  border-radius: 8px;

  .MuiOutlinedInput-root .MuiOutlinedInput-input {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: none;
  }
}

.text-field-white-transparent {
  & .MuiInputBase-root {
    color: var(--color-white);
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: $inheritDefault1;
  }

  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $inheritDefault1;
  }

  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: $inheritDefault1;
  }
}

.text-field-search-header {
  @extend .text-field-white-transparent;
  max-width: 300px;

  & .MuiInputAdornment-root {
    color: inherit;
  }
}

.search-field-exp-note-list.MuiFormControl-root {
  border: 1px solid #05203B27;
  border-radius: 8px;
  width: 300px;
}

