.mobile-page-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;

  &.mobile-page-wrapper-admin {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.mobile-page-logo {
  margin-bottom: 15px;
  width: 170px;
}

.mobile-page-logo-divider {
  position: absolute;
  top: 85px;
  left: 0;
  width: 100%;
  border-bottom-width: 2px !important;
  opacity: .6;
}

.mobile-page-banner-wrapper {
  display: flex;
  flex-direction: column;

  & .mobile-page-banner {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}

.mobile-page-banner {
  margin-top: 50px;
  border-radius: 20px;
  width: 100%;
  background: #D8F4F7;
  padding: 20px;
  overflow: hidden;

  & .MuiTypography-h4 {
    font-size: 18px;
    font-weight: 500;
    color: #546A78;
    line-height: 1.2;
  }

  & .MuiTypography-h3 {
    font-size: 22px;
    font-weight: 600;
    color: #05203B;
    line-height: 1.3;
  }

  & .MuiTypography-h3, .MuiTypography-h4 {
    margin-bottom: 12px;
  }

  & .MuiTypography-body1 {
    font-weight: 500;
    color: #586C7A;
    letter-spacing: -0.3px;
  }

  &.admin-banner {
    position: relative;
    background: #05203B;
    height: 250px;
    overflow: visible;

    & .MuiTypography-h4 {
      font-weight: 400;
      color: #94A3B4;
    }

    & .MuiTypography-h3 {
      font-weight: 400;
      color: #fff;
    }
  }

  .mobile-page-desktop-screen {
    position: absolute;
    height: 160px;
    bottom: -30px;
    left: 20px;
    z-index: 2;
  }

  .mobile-page-mobile-screen {
    position: absolute;
    height: 400px;
    bottom: -85%;
    right: calc(-30% + 10px);
  }

  .mobile-page-store-img {
    max-height: 39px;
    max-width: 50%;
  }

  &.mobile-page-banner-only-traveller {
    flex-grow: 1;

    .mobile-page-mobile-screen {
      position: relative;
      height: auto;
      bottom: unset;
      right: unset;
      flex-grow: 1;
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
    }
  }
}

@media (min-width: 0) {
  .mobile-page-banner-only-traveller {
    flex-direction: column;
    min-height: unset;

    .mobile-page-mobile-screen {
      margin: 40px 20px -20px 20px;
    }
  }
}

@media (min-width: 568px) {
  .mobile-page-banner-only-traveller {
    flex-direction: row;
    min-height: 175px;

    .mobile-page-mobile-screen {
      margin: 7px 14px -20px 45px;
    }
  }
}
