@import "src/assets/breakpoints";
@import "src/assets/core/variables";

$common-staff-row-skeleton-height: 77px;

.enabled-filter-wrapper {
  display: flex;
  justify-content: space-between;
  width: 160px;
  min-width: 160px;
  height: 50px;
  background-color: $white;
  border: 1px solid rgba(5, 32, 59, 0.153);
  border-radius: 8px;
  padding: 8px 0 8px 10px;
  position: relative;

  .MuiFormControlLabel-root {
    margin-left: 0;
  }
}

.travel-policy-filter-wrapper {
  position: relative;
  border-radius: 8px;
  background-color: $white;
  width: 230px;
}

.role-filter-wrapper {
  position: relative;
  border-radius: 8px;
  background-color: $white;
  width: 170px;
}

.disabled-user-item {
  position: absolute;
  top: -2px;
  left: 20px;
}

@mixin user-item($background-color, $icon-color) {
  border-radius: 12px;
  height: 50px;
  background-color: $background-color;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 15px;
  cursor: pointer;
  flex-grow: 0;
  padding-right: 10px;

  svg {
    color: $icon-color;
  }
}

.travel-policy-user-item {
  @include user-item(#D4F6E4, #2DD37A);
}

.project-user-item {
  @include user-item(#F8DFE7, #D32D66);
}

.credit-card-user-item {
  @include user-item(#3315D62F, #3315D6);
}

.tarif-user-item {
  @include user-item(#0F61B419, #05203B);
}

.user-item-disabled {
  opacity: .6;
  cursor: default;
}

.user-activation-switch {
  & > .MuiBox-root {
    text-align: right;
  }
}

.credit-card-skeleton.MuiSkeleton-root {
  height: 240px;
}

.expense-skeleton.MuiSkeleton-root {
  height: 260px;
}

.edit-picture {
  width: 175px;
  padding: 8px;
  height: 33px;
  border-radius: 6px;
  background-color: rgba(20, 198, 213, .1);
  color: rgba(121, 145, 170, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media (min-width: 900px) {
  .edit-picture {
    margin: 0 auto;
  }
}

@include media-breakpoint-up(sm) {
  .credit-card-skeleton.MuiSkeleton-root {
    height: $common-staff-row-skeleton-height;
  }
}

@include media-breakpoint-up(md) {
  .expense-skeleton.MuiSkeleton-root {
    height: $common-staff-row-skeleton-height;
  }
}

@include media-breakpoint-up(md) {
  .user-avatar-container {
    flex-basis: 200px;
    flex-grow: 0 !important;

    & > .MuiBox-root {
      width: fit-content;
    }
  }

  .user-activation-container {
    flex-basis: 200px;
    flex-grow: 0 !important;
  }

  .user-activation-switch {
    & > .MuiBox-root {
      text-align: left;
    }
  }
}
