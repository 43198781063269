$primary: #5383ff;
$primaryLight: #495368;
$primaryDark: #09142a;
$secondary: #3d4977;
$inheritDefault1: #fefefe;
$inheritDefault2: #f8f9ff;

$second: #070919;
$indigo: #7420ff;
$purple: #793de6;
$pink: #fc26a4;
$red: #f83245;
$orange: #f4772e;
$yellow: #ffc926;
$green: #1bc943;
$teal: #18e1a5;
$cyan: #27dcf3;
$blue: #011d59;

// colori Ovosodo
$green-primary: rgba(20, 198, 213, 1);
$green-primary-light: rgba(20, 198, 213, .1);
$active-bg: #D4F6E4;
$active-label: #2DD37A;

// status-pill
$status-pill-active-bg: $active-bg;
$status-pill-active-text: $active-label;
$status-pill-inactive-bg: #D32D66;
$status-pill-inactive-text: #ffffff;
$status-pill-expired-bg: #CCD7E3;
$status-pill-expired-text: #4D5E71;
$status-pill-future-bg: #CCD7E3;
$status-pill-future-text: #4D5E71;


// subtitle1
$subtitle1: #05203B9A;

// h6
$h6: #0E053B80;

:export {
  primary: $primary;
  inheritDefault1: $inheritDefault1;
  inheritDefault2: $inheritDefault2;
  secondary: $secondary;
  red: $red;
  green: $green;
  orange: $orange;
  blue: $blue;
  subtitle1: $subtitle1;
  h6: $h6;
  greenPrimary: $green-primary;
}
