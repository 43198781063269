.file-viewer-img {
  width: 100%;
}

.file-viewer-frame {
  overflow: auto;
  width: 100%;
  border: none;
}

.file-viewer-frame-full-height {
  @extend .file-viewer-frame;
  height: 100% !important;
}
