//  Core

.MuiDialogTitle-root {
  padding: 16px !important;
  font-size: 1.3rem !important;
}

//.dialog-title-uppercase {
//  text-transform: uppercase;
//}

.MuiDialogContent-root {
  padding: 16px 30px !important;
  min-height: 80px;
}

.MuiDialogActions-root {
  padding: 12px 30px !important;
}

// Edit
.edit-dialog {
  & .MuiDialog-paperFullScreen {
    border-radius: 0.5rem;
  }
}

// Loader
// ATTENZIONE: necessita di un componente con position: relative
// ad esempio nei Dialog si può mettere nel <DialogContent>
.loader.MuiBackdrop-root {
  background-color: rgba(255, 255,255, .4);
  position: absolute;
  z-index: 50;
}

.modal-announcement-banner {
  background-color: #FDF4F7;
  color: #D32D66;
  margin: -18px -20px 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
}

.modal-announcement-text {
  font: normal normal normal 15px/10px Jost;
  letter-spacing: 0;
}

