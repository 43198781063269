.MuiCheckbox-root.MuiCheckbox-colorPrimary {

  position: relative;
  z-index: 0;

  &:after {
    content: '';
    background-color: $white;
    height: 15px;
    width: 16px;
    position: absolute;
    z-index: -1;
  }

  &.Mui-checked {
    .MuiSvgIcon-root {
      color: $green-primary;
    }
  }
}
