// Spacing
$spacer:          1rem;
$layout-spacer:   ($spacer * 2);

// Transition
$transition-base: all .2s ease-in-out;

// Color system
$white:    #fff;
$gray-100: #f8f9ff;
$gray-200: #f4f5fd;
$gray-300: #eeeff8;
$gray-400: #e6e7f1;
$gray-500: #dfe0ea;
$gray-600: #d1d2db;
$gray-700: #a6a6b9;
$gray-800: #7a7b97;
$gray-900: #3b3e66;
$black: #070919;

$primary-neutral:       lighten(#d6daee, 6%);
$secondary-neutral:     lighten(#f5f6f7, 6%);
$success-neutral:       lighten(#ccf4dc, 6%);
$info-neutral:          lighten(#ccf7fa, 6%);
$warning-neutral:       lighten(#fff4d8, 5%);
$danger-neutral:        lighten(#ffd6db, 6%);
$light-neutral:         lighten(#fdfdff, 6%);
$dark-neutral:          lighten(#e4e4ea, 6%);
$first-neutral:         lighten(#cce9fe, 6%);
$second-neutral:        lighten(#d0d2da, 6%);

$theme-colors-neutral: ();
$theme-colors-neutral: map-merge(
    (
      "primary":    $primary-neutral,
      "secondary":  $secondary-neutral,
      "success":    $success-neutral,
      "info":       $info-neutral,
      "warning":    $warning-neutral,
      "danger":     $danger-neutral,
      "dark":       $dark-neutral,
      "first":      $first-neutral,
      "second":     $second-neutral,
    ),
    $theme-colors-neutral
);

// Typography
$font-family-sans-serif:      "Jost", "Helvetica", "Arial", sans-serif;
$font-family-monospace:       "Times New Roman", Times, serif;
$font-family-base:            $font-family-sans-serif;

$font-size-base:  .95rem; // Assumes the browser default, typically `16px`
$display1-size:   3.5rem;
$display2-size:   3rem;
$display3-size:   2.5rem;
$display4-size:   1.7rem;
$text-muted:      $gray-600;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 900px,
  lg: 1200px,
  xl: 1536px
);

// Components
$border-width:                1px;
$border-color:                $gray-300;

$border-radius:               .65rem;
$border-radius-lg:            .75rem;
$border-radius-sm:            .29rem;
$border-radius-xs:            .2rem;

$rounded-pill:                50rem;

// List group
$list-group-color:                  null;
$list-group-bg:                     $white;
$list-group-border-color:           rgba($black, .125);
$list-group-border-width:           $border-width;
$list-group-border-radius:          $border-radius;

$list-group-item-padding-y:         .75rem;
$list-group-item-padding-x:         1.25rem;

$list-group-hover-bg:               $gray-200;
$list-group-active-color:           darken($black, 15%);
$list-group-active-bg:              $gray-300;
$list-group-active-border-color:    $list-group-active-bg;

$list-group-disabled-color:         $gray-600;
$list-group-disabled-bg:            $list-group-bg;

$list-group-action-color:           $gray-800;
$list-group-action-hover-color:     $black;

$list-group-action-active-color:    darken($black, 15%);
$list-group-action-active-bg:       $gray-300;

:export {
  breakpointXs: map-get($grid-breakpoints, xs);
  breakpointSm: map-get($grid-breakpoints, sm);
  breakpointMd: map-get($grid-breakpoints, md);
  breakpointLg: map-get($grid-breakpoints, lg);
  breakpointXl: map-get($grid-breakpoints, xl);
}
