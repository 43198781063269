$animationDelay: .3s;

.MuiDrawer-paper {
  .MuiToolbar-root {
    .MuiButtonBase-root {
      margin-top: 10px;
    }
  }
}

.MuiAppBar-root.app-header {
  background-color: transparent;
  box-shadow: none;
  transition: width $animationDelay ease-in;
}

.app-drawer {
  & > .MuiDrawer-paper {
    border-radius: 15px;
    margin-top: 20px;
    margin-left: 20px;
    height: calc(100% - 43px);
  }
}

.app-drawer-closed {

  & > .MuiDrawer-paper {
    overflow-x: hidden;
    animation: closeNav $animationDelay forwards;
  }

  & .outer-menu {
    align-self: flex-start;
  }

  .role-menu {
    animation: closeRoleMenu $animationDelay forwards;
  }

  .menu-item {
    animation: closeMenuItem $animationDelay forwards;
  }

  .MuiList-root {
    animation: closeMenuListItem $animationDelay forwards;
  }

}

.nav-closed {
  animation: closeMainNav $animationDelay forwards;
}

@keyframes closeMainNav {
  0% {
    width: 360px;
  }
  25% {
    width: 300px;
  }
  50% {
    width: 240px;
  }
  75% {
    width: 180px;
  }
  100% {
    width: 126px;
  }
}

@keyframes closeNav {
  0% {
    width: 300px;
    align-items: unset;
  }
  25% {
    width: 240px;
    align-items: unset;
  }
  50% {
    width: 180px;
    align-items: unset;
  }
  75% {
    width: 120px;
    align-items: unset;
  }
  100% {
    width: 86px;
    align-items: unset;
  }
}

@keyframes closeRoleMenu {
  0% {
    height: 40px;
    opacity: 1;
  }
  25% {
    height: 30px;
    opacity: .8;
  }
  50% {
    height: 20px;
    opacity: .5;
  }
  75% {
    height: 10px;
    opacity: .3;
  }
  100% {
    opacity: 0;
    height: 0;
    display: none;
  }
}

@keyframes closeMenuItem {
  0% {
    transform: translateY(0);
    white-space: nowrap;
    opacity: 1;
  }
  25% {
    transform: translateY(-7px);
    white-space: nowrap;
    opacity: .8;
  }
  50% {
    transform: translateY(-14px);
    white-space: nowrap;
    opacity: .5;
  }
  75% {
    transform: translateY(-20px);
    white-space: nowrap;
    opacity: .3;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes closeMenuListItem {
  0% {
    transform: scaleY(100%);
  }
  30% {
    transform: scaleY(70%);
  }
  70% {
    transform: scaleY(30%);
  }
  100% {
    height: 0;
    display: none;
  }
}

.highlighted-menu {
  color: $green-primary;
}

.outer-menu.MuiListItemButton-root {
  &:hover {
    background: none;
  }
}

.inner-menu.MuiListItemButton-root {
  &:hover {
    @extend .highlighted-menu;
  }
}

.menu-item.MuiListItem-root {

  color: $h6;

  .MuiListItemText-primary {
    font-size: 15px;
    line-height: 15px;
    letter-spacing: 0;
  }

  &:hover {
    //color: $green-primary;
    background: none;
  }
}

.outer-menu .MuiTypography-h5 {
  font-size: 15px !important;
  line-height: 15px;
  letter-spacing: 0.3px;
  font-weight: 600;
}

.outer-menu-btn {
  background-color: transparent;
  color: #7991AA;
  border-radius: 8px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.outer-menu-btn-active {
  @extend .outer-menu-btn;
  background-color: $green-primary;
  color: $white;
}

.menu-item-active.MuiListItem-root {
  color: $green-primary;
  background: none;
}

.menu-item-active.MuiListItemText-root {
  color: $green-primary;
  background: none;
}

.user-box {
  display: flex;

  & .MuiButton-root {
    display: flex;
    align-items: center;
  }

  & .MuiAvatar-root {
    width: 50px;
    height: 50px;
  }

  & .user-name {
    font-weight: bold;
    line-height: 1;
  }
}

.user-box-menu {
  font-size: 14px;

  & .MuiList-root {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & .MuiAvatar-root {
    width: 50px;
    height: 50px;
  }

  span {
    opacity: .5;
  }

  & .MuiDivider-root {
    width: 100%;
  }
}

.app-main {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  position: relative;
}
