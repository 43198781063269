@import "src/assets/core/variables";
@import "src/assets/breakpoints";
@import "src/assets/core/variables-mui";
@import "src/assets/components/Button/base";

.date-filter-wrapper {
  position: relative;
  border-radius: 8px;
  background-color: $white;
  width: 330px;
  border: 1px solid #05203B27;

  .MuiFilledInput-root {
    border: none;
  }

  .MuiInputAdornment-positionEnd {
    padding-right: 12px;
  }
}

.date-filter-wrapper2 {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  background-color: $white;
  width: 320px;
  border: 1px solid #05203B27;

  .MuiFilledInput-root {
    border: none;
  }
}

.exp-note-dashboard {
  .MuiTypography-h5 {
    letter-spacing: 0;
    font-weight: 600;
    font-size: 15px;
    line-height: 15px;
  }

  .MuiTypography-h6 {
    letter-spacing: 0;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
  }

  .exp-note-amount {
    display: flex;
    justify-content: space-between;
    max-width: 285px;

    .MuiTypography-body1:last-child {
      color: #7991AA;
      font-weight: 500;
    }
  }

  .text-danger, .text-warning {
    .MuiTypography-body1:last-child {
      color: inherit;
    }
  }
}

@include media-breakpoint-down(md) {
  .tab-bar-exp-note {
    position: absolute;
    top: 120px;
    left: 50px;
  }
}

@include media-breakpoint-down(sm) {
  .tab-bar-exp-note {
    position: absolute;
    top: 120px;
    left: 30px;
  }
}

.MuiAccordion-root.exp-note-expense-list-group {
  border: 2px solid #CCD7E3;
  border-radius: 13px;
  padding: 10px;
  box-shadow: none;
  background-color: #EDF1F5B2;
  margin-top: 16px;

  &:before {
    content: '';
    display: none;
  }

  &:last-of-type {
    border-radius: 13px;
  }

  .expand-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    width: 44px;
    height: 44px;
    background-color: $white;
  }

  .action-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: $white;
    padding: 4px;
  }

  &.Mui-expanded {
    background-color: $white;

    .expand-button {
      background-color: rgba(204, 215, 227, 0.15);
      color: #7991AA;

      svg {
        opacity: 1;
      }
    }

    .action-button {
      background-color: rgba(204, 215, 227, 0.15);
      color: #7991AA;

      svg {
        opacity: 1;
      }
    }

    div > .MuiTypography-root {
      color: #7991AA;
    }
  }

  .MuiAccordionSummary-root {
    min-height: auto;
    padding-left: 0;

    .MuiAccordionSummary-content {
      margin: 0;
    }
  }
}

.document-hidden-row.MuiTableRow-root {
  height: 0;
  visibility: collapse;

  & > .MuiTableCell-root {
    border-color: transparent;
  }
}

.expense-filter-wrapper {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: $white;
  height: 48px;
  border: 1px solid #05203B27;
  padding: 0 8px;
}

@mixin expense-filter($bg-color, $icon-color) {
  border-radius: 8px;
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  background-color: $bg-color;
  color: $icon-color;

  &:not(:first-child) {
    margin-left: 5px;
  }

  svg {
    color: $icon-color;
  }

  .MuiTypography-root {
    font-size: 13px;
  }
}

.expense-filter-disabled {
  @include expense-filter(#EDF1F5, #89A6C3);
  opacity: .5;
}

.expense-filter-anomaly {
  @include expense-filter(#D32D661A, $red);
}

.expense-filter-creditcard {
  @include expense-filter(#FFA70019, #FFA700);
}

.expense-filter-accounting {
  @include expense-filter(#EDF1F5, #89A6C3);
}

.expense-list-cell-small-size.MuiTypography-root {
  font-size: 75%;
}

@mixin exp-note-attach-container-cta-button($border-radius) {
  position: absolute;
  top: 10px;
  width: 45px;
  height: 45px;
  border-radius: $border-radius;
  background-color: #EDF1F5;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & > svg {
    color: #7991AA;
  }
}

.exp-note-attach-container {
  background-color: #CCD7E3;
  border-radius: 13px;
  padding: 60px 30px 50px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  & > .full-size-button {
    @include exp-note-attach-container-cta-button(13px);
    right: 70px;
  }

  & > .delete-button {
    @include exp-note-attach-container-cta-button(50%);
    right: 10px;
  }
}

.exp-note-attach-container-pdf {
  height: 625px;
}

.exp-note-attach-container-full-height {
  @extend .exp-note-attach-container;
  height: 100%;
}

.additional-expense {
  padding: 14px;
  border: 2px dashed #d5d4d4;
  border-radius: 6px;
  position: relative;

  .delete-button {
    position: absolute;
    top: -15px;
    right: -15px;
    border-radius: 50%;
    background-color: $white;
    border: 1px solid lightgrey;
  }
}

.additional-expense-button {
  background: #DDF7F9 0 0 no-repeat padding-box;
  border: 2px dashed #14C6D54D;
  border-radius: 8px;
  height: 67px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #4D5E71;

  .MuiTypography-root {
    user-select: none;
  }
}

.additional-expense-button-disabled {
  cursor: default;
}

.maximum-approval.MuiToggleButtonGroup-root {

  & .MuiToggleButton-root {
    height: 28px;

    &.maximum-approved.Mui-selected {
      background-color: #D4F6E4;

      & svg {
        color: $green;
      }
    }

    &.maximum-rejected.Mui-selected {
      background-color: #D32D661A;

      & svg {
        color: $red;
      }
    }
  }
}

.map-button {
  @extend .cta-button-small;
  padding: 20px 12px !important;

  & > span {
    margin-right: 0;
  }
}

.add-item-button.MuiButton-root {
  border-radius: 40px;
  background-color: $green-primary;
  color: $white;
  text-transform: none;
  padding: 4px 12px;
  text-align: left;
  line-height: 1;

  &:hover {
    background-color: $green-primary;
    color: $white;
  }
}

.edit-expense-responsive-warning {
  display: none;
}

.edit-expense-container {
  display: flex;
  height: 100%;
}

@media only screen and(max-width: map-get($grid-breakpoints, "md")) and(orientation: portrait) {
  .edit-expense-container {
    display: none;
  }

  .edit-expense-responsive-warning {
    display: block;
  }
}

.exp-note-chart-wrapper {
  min-height: 350px;
}

@include media-breakpoint-down(lg) {
  .exp-note-chart-wrapper {
    min-height: 380px;
  }
}

@include media-breakpoint-down(md) {
  .exp-note-chart-wrapper {
    min-height: 450px;
  }
}

//@media only screen and
//(max-width: map-get($grid-breakpoints, "md")) and
//(orientation: landscape) {
//  .exp-note-chart-wrapper {
//    min-height: 450px;
//  }
//}

.movement-link-doc thead {
  height: 42px;
}


@mixin exp-note-detail-cta-button($background-color: $green-primary, $color: $white) {
  &.MuiButton-root {
    padding: 8px 26px;
    box-shadow: none;
    text-transform: none;
    background-color: $background-color;
    color: $color;
    border-radius: 40px;
    height: 48px;
    width: 184px;

    font-weight: 500;
    font-size: 15px;

    .MuiTypography-root {
      text-transform: none;
      line-height: 1.05;
      text-align: center;

      font-weight: 500;
      font-size: 15px;
    }

    &:hover {
      background-color: $background-color;
      color: $color;
      box-shadow: none;
    }
  }
}

.exp-note-detail-add-item-button {
  @include exp-note-detail-cta-button;
}

.exp-note-detail-cta-primary-button {
  @include exp-note-detail-cta-button(rgba(51, 21, 214, 1), $white);
}

.exp-note-detail-cta-button {
  @include exp-note-detail-cta-button(rgba(204, 215, 227, 0.5), rgba(121, 145, 170, 1));
}

