@import "src/assets/breakpoints";
@import "src/assets/core/variables";
@import "src/assets/core/variables-mui";

.viewport-alert {
  height: fit-content;
  background-color: #fafad2 !important;
  margin: 20px 0 10px 0;
  padding: 15px;
  font-weight: 600;
}

.dashboard {
  max-width: 1200px;

  .MuiPaper-root {
    padding: 10px 15px;

    &.MuiPaper-rounded {
      border-radius: 10px;
    }
  }

  .MuiPaper-root.onboarding {
    height: 160px;
  }

  .MuiPaper-root.notification {
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .pro-feature-pill {
      top: -7px;
      right: 10px;
      width: 40px;
      position: absolute;
    }

    .MuiTypography-root {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      &:has(~ .pro-feature-pill) {
        color: #8d9195;
      }
    }

    .circle {
      position: absolute;
      top: 7px;
      right: 7px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }

    &.to-check {
      background-color: #C3FDEA;

      .circle {
        background-color: #4ff1bb;
      }
    }

    &.to-pay {
      background-color: #C3E3FD;

      .circle {
        background-color: #60B4FA;
      }
    }

    &.to-account {
      background-color: #E8C3FD;

      .circle {
        background-color: #CE7DFB;
      }
    }

    &.to-archive {
      background-color: #FDC3E4;
    }

    .circle {
      background-color: #fd95d0;
    }
  }

  .MuiPaper-root.chart {
    height: 300px;
    flex-grow: 1;

    & .MuiTypography-h5 {
      font-size: 13px;
      line-height: 1.1;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    & .MuiSelect-select {
      font-size: 12px;
      color: $h6;
      font-weight: 600;
    }
  }

  .MuiPaper-root.chart-total {
    flex-basis: calc(60% - 16px);
    min-width: 280px;
  }

  .MuiPaper-root.chart-category {
    flex-basis: 40%;
    min-width: 220px;
  }

  .MuiPaper-root.license {
    min-height: 180px;
    max-height: fit-content;
  }

  .MuiPaper-root.carousel {
    height: 330px;
    background-color: #E4EEFA;
  }

  .MuiPaper-root.exp-note-list {
    min-height: 310px;
  }

  .dismiss-button {
    position: absolute;
    top: 3px;
    right: -1px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: $white;
    background-color: #ccd7e3;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    & > svg {
      font-size: .85em;
      padding-left: 1px;
    }
  }

  .license-logo {
    width: 90px;
  }
}

@include media-breakpoint-down(md) {
  .dashboard {
    .MuiPaper-root.onboarding {
      height: 120px;

      & .MuiTypography-root {
        font-size: 15px;
      }
    }
  }
}
