.MuiTable-root {
  overflow: hidden;
}

.MuiTableHead-root {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

th.MuiTableCell-root {
  padding: 12px;
  border: none;

  & .table-header {
    font-size: 10px !important;
    line-height: 10px !important;
    letter-spacing: 0.5px !important;
  }
}

td.MuiTableCell-root {
  border-bottom-width: 3px;
  position: relative;
  z-index: 0;
  padding: 12px;

  .MuiTypography-root {
    user-select: none;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: $white;
  }
}

tr.MuiTableRow-root:last-child td.MuiTableCell-root {
  border-bottom: none;

  :after {
    display: none;
  }
}

@mixin highlight-cell {
  .MuiTableCell-root {
    border-bottom-width: 3px;
    border-color: white;

    &:after {
      display: none;
    }
  }

  .MuiTableCell-root:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .MuiTableCell-root:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

tr.MuiTableRow-hover {
  span.show-cell-on-hover {
    opacity: 0;
  }

  &:hover {
    background-color: #E7FCFE !important;

    @include highlight-cell();

    span.show-cell-on-hover {
      opacity: 1;
    }
  }
}

tr.MuiTableRow-root.selected-row {
  background-color: #E7FCFE;

  @include highlight-cell();
}

tr.MuiTableRow-root.aggregate-row {

  & table {
    overflow: hidden;
  }

  & > .MuiTableCell-root {
    padding: 0;
  }

  & .MuiTableRow-root {
    .MuiTableCell-root {
      padding-top: 6px;
    }

    .MuiTableCell-root {
      border-bottom-color: transparent;
    }
  }

  & .MuiTableRow-root:not(:last-child) {
    & .MuiTableCell-root {
      padding-bottom: 6px;
    }
  }
}


table.table-without-header {
  margin-top: -35px;

  .MuiTableHead-root {
    border: none;
  }

  & th.MuiTableCell-head > span {
    opacity: 0;
  }
}

.MuiTable-root.user-selectable {
  td.MuiTableCell-root {
    .MuiTypography-root {
      user-select: text;
    }
  }
}
