@import "../../assets/core/variables";
@import "../../assets/core/variables-mui";
@import "../../assets/components/Button/base";

.login-container {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-form-wrapper {
    flex-grow: 1;
    justify-content: space-between;
    max-width: 940px;
    height: 610px;
    border-radius: 25px;
    background: #D9F4F7;

    margin: 0 20px;
    padding: 15px;

    .MuiFilledInput-input {
      padding-top: 4px;
    }

    & > div {
      padding: 40px;
      flex-basis: 50%;
      position: relative;
    }

    & > div:first-child {
      padding-right: 0;
    }

    .login-title {
      font-size: 22px;
      color: #05203B;
    }

    .MuiFilledInput-root {
      background-color: #fff !important;
      border-color: transparent !important;
    }

    .MuiInputLabel-root.Mui-focused {
      color: $green-primary;
    }

    .field-label {
      font-size: 15px;
      line-height: 19px;
      font-weight: 500;
      color: #05203B;
      margin-bottom: 5px;
    }

    .login-button {
      @include cta-button(#05203B, #fff, 50px);
    }

    .password-recovery {
      text-decoration: underline;
      cursor: pointer;
      text-align: center;
    }
  }

  .registration-container {
    flex-grow: 1;
    border-radius: 25px;
    background: #05203B;

    color: #fff;

    .registration-question {
      font-size: 18px;
    }

    .registration-title {
      font-size: 45px;
      line-height: 54px;
      font-weight: 600;
    }

    .registration-subtitle {
      font-size: 20px;
      line-height: 28px;
      color: #B3BBC3;
    }

    .registration-app-name {
      color: #fff;
    }

    .registration-button {
      @include cta-button($green-primary, #fff, 50px);
    }
  }

  .login-button-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    p {
      font-size: 16px;
      font-weight: 600;
    }
  }
}

@media (max-width: 750px) {
  .login-container .registration-container .registration-title {
    font-size: 35px;
    line-height: 40px;
  }
}
