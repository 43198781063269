.tooltip-zts {
  &.MuiTooltip-tooltip {
    background-color: $white;
    color: #000;
    font-size: 15px;
    box-shadow: 0 5px 8px 2px rgba(0, 0, 0, 0.2);
    font-weight: 400;
    padding: 8px 8px;
    letter-spacing: 0.3px;
  }

  &.MuiTooltip-tooltipPlacementTop {
    margin-bottom: 3px !important;
  }

  &.MuiTooltip-tooltipPlacementBottom {
    margin-top: 3px !important;
  }
}
