//  Core

.delete-fab {
  &.MuiFab-root {
    background-color: $red;
    color: $white;
  }
  &.MuiFab-root:hover {
    background-color: #c91425;
  }
}

