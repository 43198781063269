.expense-upload-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-width: 2px;
  border-style: solid;
  border-radius: 5px;
  border-color: $secondary;
  cursor: pointer;
  padding: 15px;

  &:hover {
    color: $primary;
    border-color: $primary;
  }
}
