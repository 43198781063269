$edit-button-size: 56px;

// Pulsante per aprire la card
.card-open-button {
  &.MuiButton-contained {
    box-shadow: none;
    background-color: $green-primary-light;
    color: #7991AA;
    padding: 7px 10px 6px 10px;
    min-width: auto;

    .MuiTypography-root {
      text-transform: none;
    }

    &:hover {
      box-shadow: none;
      background-color: $green-primary;
      color: $white;
    }
  }
}

// pulsante per:
//  - creare un nuovo elemento da una lista
//  - modificare un elemento dalla pagina di dettaglio
@mixin edit-button($size: $edit-button-size, $background-color: $green-primary, $color: $white) {
  &.MuiIconButton-root {
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: $background-color;
    color: $color;

    &:hover {
      background-color: $background-color;
    }
  }
}

.edit-button {
  @include edit-button;
}

.trash-button {
  @include edit-button($edit-button-size, #0ba2ae);
}

.approve-all-button {
  @include edit-button($edit-button-size, #D4F6E4, $green);
}

.reject-all-button {
  @include edit-button($edit-button-size, #D32D661A, $red);
}

// Pulsante azione nelle table
.cta-list-button {
  &.MuiButton-contained {
    box-shadow: none;
    background-color: $green-primary-light;
    color: #7991AA;
    padding: 5px 10px 5px 10px;
    min-width: auto;
    border-radius: 10px;

    .MuiTypography-root {
      text-transform: none;
    }

    &:hover {
      box-shadow: none;
      background-color: $green-primary;
      color: $white;
    }
  }
}

.delete-button {
  @include edit-button($edit-button-size, #FDF4F7, $red);
}

// pulsante salva/annulla: utilizzato nella pagina di inserimento e modifica
@mixin cta-button($background-color, $color, $height: 52px) {
  &.MuiButton-contained {
    padding: 15px 30px;
    box-shadow: none;
    height: $height;
    background-color: $background-color;
    color: $color;
    border-radius: 10px;

    .MuiTypography-root {
      text-transform: none;
      line-height: 1.05;
      text-align: left;
    }

    &:hover {
      box-shadow: none;
      background-color: $background-color;
      color: $color;
    }
  }
}

.cta-button {
  @include cta-button(rgba(204, 215, 227, 0.5), rgba(121, 145, 170, 1));
}

.cta-button-primary {
  @include cta-button(rgba(51, 21, 214, 1), $white);
}

.cta-button-primary-small {
  @include cta-button(rgba(51, 21, 214, 1), $white, 34px);
  padding: 15px !important;
}

.cta-button-danger-small {
  @include cta-button($red, $white, 34px);
  padding: 15px !important;
}

.cta-button-small {
  @include cta-button(rgba(204, 215, 227, 0.5), rgba(121, 145, 170, 1), 34px);
  padding: 15px !important;
}

.delete-button {
  @include cta-button(#FDF4F7, #D32D66);
}

.filter-button {
  @include cta-button(rgba(204, 215, 227, 0.5), rgba(121, 145, 170, 1));
  height: 40px !important;
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px 15px !important;
}

.restore-button {
  @include cta-button(#E2F0D9, #000, 32px);
  padding: 15px 20px !important;
}

.cta-button-pref {
  @include cta-button(#e7f9fb, #14c6d5)
}

.save-button {
  @include edit-button($edit-button-size, #3315d6, $white)
}

.cta-button-dashboard {
  @include cta-button($green-primary, $white, 30px);
  padding: 12px !important;
}

.info-popup-confirm-button {
  @include cta-button(#2DD37A, $white, 34px);
  padding: 15px !important;
}

.info-popup-discard-button {
  @include cta-button($yellow, $white, 34px);
  padding: 15px !important;
}

.info-popup-undiscard-button {
  @include cta-button($blue, $white, 34px);
  padding: 15px !important;
}

.info-popup-cancel-button {
  @include cta-button(#d32d66, $white, 34px);
  padding: 15px !important;
}

.cta-button-cre-card-mov {
  @include cta-button($green-primary, $white, 30px);
  padding: 12px !important;
  border-radius: 6px !important;
}
