.MuiButtonBase-root.MuiRadio-root.Mui-checked {
  color: $green-primary;
}

.MuiRadio-root:not(.Mui-checked) + .MuiFormControlLabel-label {
  color: #7991AA;
}

.MuiRadio-root + .MuiTypography-root {
  user-select: none;
}
